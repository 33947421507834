export const timeStampStrToDate = (timestamp: string) => {
  const utcSeconds = +timestamp;
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  return d.toDateString();
};

export const timeStampNumToDate = (utcSeconds: number) => {
  const d = new Date(0);
  d.setUTCSeconds(utcSeconds);
  return `${d.getFullYear()}-${d.getMonth().toString().length > 1 ? d.getMonth() : `0${d.getMonth()}`}-${d.getDate().toString().length > 1 ? d.getDate() : `0${d.getDate()}`}`;
};

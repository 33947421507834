import { getUidAndAccessToken } from 'libs/codexdf/oktaAuthentication';

export const logEvent = (event: string) => {
  const uid = getUidAndAccessToken()?.uid;

  const eventObject = {
    name: `EVENT: ${event}`,
    uid: uid ? uid?.toString() : '',
  };

  return eventObject;
};

export const logError = (error: string) => {
  const uid = getUidAndAccessToken()?.uid;

  const errorObject = {
    name: `ERROR: ${error}`,
    uid: uid ? uid?.toString() : '',
  };

  return errorObject;
};

import React from 'react';

const AccentButton = (props: any) => {
  const {
    text, disabled, onClick, classStyle,
  } = props;

  let baseStyle = 'relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-CDFAccent ';
  baseStyle += (disabled ? 'cursor-not-allowed opacity-25 ' : 'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ');
  baseStyle += classStyle;

  return (
    <button
      type="button"
      className={baseStyle}
      onClick={disabled ? () => {} : onClick}
    >
      {text}
    </button>
  );
};

export default AccentButton;

import { ActionMapType } from 'libs/utils';

export interface ISyncState {
  syncing: boolean
  lastSync: number
}

export enum SyncTypes {
  StartSync = 'START_SYNC',
  StopSync = 'STOP_SYNC',
}

type TSyncPayload = {
  [SyncTypes.StartSync]: undefined
  [SyncTypes.StopSync]: number
};

export type TSyncActions = ActionMapType<TSyncPayload>[keyof ActionMapType<TSyncPayload>];
const syncReducer = (state: ISyncState, action: TSyncActions) => {
  let updatedState : ISyncState | null = null;
  switch (action.type) {
    case SyncTypes.StartSync:
      updatedState = {
        ...state,
        syncing: true,
        lastSync: 0,
      };
      break;

    case SyncTypes.StopSync:
      updatedState = {
        syncing: false,
        lastSync: action.payload,
      };
      break;

    default:
      return state;
  }

  if (!updatedState) return state;

  return updatedState;
};

export default syncReducer;

import * as React from 'react';
import { Location, Navigate, useLocation } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import LoggedOut from 'res/images/utils/LoggedOut.png';
import AccentButton from 'libs/components/basic/AccentButton';

const OktaError: React.FC<{ error: Error }> = ({ error }) => {
  if (error.name && error.message) {
    return (
      <p>
        {error.name}
        :
        {' '}
        {error.message}
      </p>
    );
  }
  return (
    <p>
      Error:
      {error.toString()}
    </p>
  );
};

export type OnAuthResumeFunction = () => void;

interface LoginCallbackProps {
  errorComponent: React.ComponentType<{ error: Error }>;
  onAuthResume: OnAuthResumeFunction | null;
}

interface PrevPathLocation {
  prevPath: string
}

// TODO: Clean up this file
const LoginCallback: React.FC<LoginCallbackProps> = ({
  errorComponent, onAuthResume = null,
}) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [callbackError, setCallbackError] = React.useState(null);
  const location: Location = useLocation();

  const ErrorReporter = errorComponent || OktaError;

  // Handle the auth login - after screen is painted
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore OKTA-464505: backward compatibility support for auth-js@5
    const { isInteractionRequired } = oktaAuth.idx.isInteractionRequired;
    if (isInteractionRequired) {
      // the okta version of this calls onAuthResume() to let the developer set
      // history() which is obsolete and sadly navigate() cannot be used this way
      if (onAuthResume != null) {
        onAuthResume();
        return;
      }
    }

    // Presumably perform the actual login...
    oktaAuth.handleLoginRedirect().catch((e) => {
      setCallbackError(e);
    });
  }, [oktaAuth, onAuthResume]);

  // show error if any
  const authError = authState?.error;
  const displayError = callbackError || authError;
  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  // rather than showing nothing - show something useful
  if (authState != null && authState.isAuthenticated) {
    const state = location.state as PrevPathLocation;
    if (state && state.prevPath) {
      return <Navigate to={state.prevPath} />;
    }

    return <Navigate to="/app" />;
  }
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div
        className="w-3/5 border-2 border-grey-500 p-8 flex flex-col items-center justify-center rounded-lg"
      >
        <img className="h-48 w-48 rounded-full" src={LoggedOut} alt="logged out img" />
        <div className="mt-6 mb-4">
          You have been logged out due to inactivity
        </div>
        <AccentButton text="Log In" onClick={() => oktaAuth.signInWithRedirect()} />
      </div>
    </div>
  );
};

export default LoginCallback;

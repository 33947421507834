import React, {
  createContext, useReducer, Dispatch, useMemo,
} from 'react';
import syncReducer, { ISyncState, TSyncActions } from './Sync-Reducer';

const emptySyncState : ISyncState = {
  syncing: false,
  lastSync: 0,
};

export interface ISyncContext {
  state: ISyncState
  dispatch: Dispatch<TSyncActions>
}

export const SyncContext = createContext<ISyncContext>({
  state: emptySyncState,
  dispatch: () => null,
});

const mainReducer = (state: ISyncState, action: TSyncActions) => {
  const newState: ISyncState = syncReducer(state, action);
  return {
    syncing: newState.syncing,
    lastSync: newState.lastSync,
  };
};

export const SyncContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(mainReducer, emptySyncState);
  const { children } = props;

  const syncMemo = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <SyncContext.Provider value={syncMemo}>
      { children }
    </SyncContext.Provider>
  );
};

export default SyncContextProvider;

import { OktaAuth } from '@okta/okta-auth-js';

export const okta: OktaAuth = new OktaAuth({
  issuer: `${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/default`,
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  responseMode: 'fragment',
  tokenManager: { storage: 'sessionStorage' },
  useInteractionCodeFlow: true,
});
okta.start();

export const getUidAndAccessToken = () => {
  const authState = okta.authStateManager.getAuthState();

  if (!authState) return null;

  const { isAuthenticated } = authState;
  if (!isAuthenticated) return null;

  const { accessToken } = authState;
  if (!accessToken) return null;

  const { uid } = accessToken.claims;
  return {
    uid,
    accessToken: accessToken.accessToken,
  };
};

export const signInWithEmailAndPassword = async (username: string, password: string) => {
  const results = await okta.signIn({ username, password });
  console.log(results);

  if (results && results.user && results.user.profile) {
    return true;
  }
  return false;
};

export const signOut = async () => {
  await okta.signOut();
};

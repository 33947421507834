export interface IMockUserData {
  tenant: string
  admin: boolean
}

const mockLoginMap = new Map<string, IMockUserData>();

mockLoginMap.set('samorir@codexdf.com', {
  tenant: 'Codex DF',
  admin: true,
});

export const mockUserInfo = (username: string) : IMockUserData | null => {
  if (mockLoginMap.has(username)) {
    const userInfo = mockLoginMap.get(username)!;

    return userInfo;
  }

  return null;
};
